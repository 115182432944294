import { createInertiaApp } from '@inertiajs/svelte';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { appConfigStore } from './stores';

const appName = window.document.getElementsByTagName('title')[0]?.innerText || 'Onix-Systems';

createInertiaApp({
    title: (title) => `${title} - ${appName}`,
    resolve: (name) => resolvePageComponent(`./Pages/${name}.svelte`, import.meta.glob('./Pages/**/*.svelte')),
    setup({ el, App, props }) {
        const { app: app } = props.initialPage.props;
        appConfigStore.set(app);
        new App({ target: el, props, hydrate: true });
    },
    progress: {
        color: '#4B5563',
    },
});
