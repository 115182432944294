import {writable, readable, Writable} from 'svelte/store';

export interface appConfigInterface {
  domain: string;
  available_locales: string[];
  street_name: string;
  city_name: string;
  app_locale: string;
  last_update:string,
  current_date:string,
  available_languages: string,
  street_count: number,
  photo_url:string,
  version_feedback:boolean,
  zoom:number,
  facebook_app_id: string;
  captcha: {
    site_key: string;
    secret_key: string;
  }
  map: {
    google_map_key: string;
    zoom: number;
    default_lng: string;
    default_lat: string;
  },
  analytics: {
    'google_site_verification': string;
    'google_tag_measurement_id': string;
  }
}

export const appConfigStore: Writable<appConfigInterface | null> = writable(null);
